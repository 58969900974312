<div *ngIf="refDataLoaded">
    <div *ngIf="form.get('operatorRegisterNumber').hasError('operatorExists')" class="my-3">
        <ux-control-feedback typeClass="danger">
            {{ 'screens.account.create.company.validation.error.operatorExists' | translate }}
        </ux-control-feedback>
    </div>

    <div *ngIf="operatorId" class="hideItem">
        <ux-form-control  [formControl]="operatorId" [isReadOnly]="isReadOnly">
        </ux-form-control>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.company.name.label' | translate }}" [isRequired]="!isReadOnly">
            <ux-form-control  [formControl]="operatorName" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || operatorName.touched">
            <ux-control-feedback *ngIf="operatorName.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.company.email.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control  [formControl]="operatorEmail" [isReadOnly]="isReadOnly" [maxlength]="254" [isShowMaxlengthCounter]="false">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || operatorEmail.touched">
            <ux-control-feedback *ngIf="operatorEmail.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
            <ux-control-feedback *ngIf="operatorEmail.hasError('email')" typeClass="danger">
                {{ 'screens.common.message.field.valid.email' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.licence.label' | translate }}">
            <ux-form-control  [formControl]="operatorLicenseNumber" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}">
            </ux-form-control>
        </ux-form-group>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.national.company.register.number.label' | translate }}"
                       infoLabel="{{ isReadOnly ? '' : 'screens.account.create.national.company.register.number.info' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control  [formControl]="operatorRegisterNumber" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || operatorRegisterNumber.touched || operatorCountryOfRegistration.touched">
            <ux-control-feedback *ngIf="operatorRegisterNumber.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
            <ux-control-feedback *ngIf="operatorRegisterNumber.hasError('natExists')" typeClass="danger">
                {{ 'screens.account.validation.error.natExists' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.vat.label' | translate }}">
            <ux-form-control  [formControl]="operatorVat" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || operatorVat.touched">
            <ux-control-feedback *ngIf="operatorVat.hasError('vatExists')" typeClass="danger">
                {{ 'screens.account.validation.error.vatExists' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.country.code.label' | translate }}" [isRequired]="!isReadOnly">
            <eui-autocomplete isItemsSorted [readonly]="isReadOnly" [formControl]="operatorCountryOfRegistration"
                              [autocompleteData]="postingCountries" [itemsSortOrder]="'ASC'"
                              placeholder="{{ isReadOnly ? '' : 'screens.common.placeholder.select' | translate }}"
                              (selectionChanged)="validateNat()">
            </eui-autocomplete>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors
                                        || operatorCountryOfRegistration.hasError('requiredValidCountry')
                                        || operatorCountryOfRegistration.hasError('validCountry')">
            <ux-control-feedback *ngIf="operatorCountryOfRegistration.hasError('requiredValidCountry')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
            <ux-control-feedback *ngIf="operatorCountryOfRegistration.hasError('validCountry')" typeClass="danger">
                {{ 'screens.common.message.field.valid.country' | translate }}
            </ux-control-feedback>
        </div>
    </div>
</div>

